import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const wsOutput = document.getElementById("connected-in") as HTMLDivElement;

const httpOutput = document.getElementById("request-in") as HTMLDivElement;

let start = Date.now();

const roomName = window.location.pathname.slice(1).replaceAll("/", "-").trim();

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.

const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: roomName || "test-perf-room",
});

conn.addEventListener("open", () => {
  const end = Date.now();
  console.log(`Connection established in ${end - start}ms`);
  wsOutput.innerHTML += `${end - start}ms <br/>`;
});

setInterval(() => {
  // disconnect and reconnect
  start = Date.now();
  conn.close();
  conn.reconnect();
}, 300);

setInterval(() => {
  const start = Date.now();
  PartySocket.fetch({
    host: PARTYKIT_HOST,
    room: roomName || "test-perf-room",
  }).then((res) => {
    const end = Date.now();
    console.log(`Fetch completed in ${end - start}ms`);
    httpOutput.innerHTML += `${end - start}ms <br/>`;
  });
}, 300);
